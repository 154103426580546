.Experience-Wrapper {
    display: grid;
    grid-template-columns: 60px auto;
    gap: calc(var(--app-padding) / 4)
}
.Experience-Logo {
    margin-top: calc(var(--textSize300) - 2px);

    svg {
        width: 60px !important;
        height: auto !important;
    }
}

.Experience {
    display: flex;
    flex-direction: column;
}
.Experience-Header {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Experience-Header h5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: var(--fontSecondaryStack);
    color: var(--headerColor);
    font-weight: 900;
}
.Experience-SubHeader {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: flex-end;
    color: var(--gray500);
    font-size: var(--textSize50);
    width: 100%;
    // justify-content: space-between;
}
.Experience-Company {
    font-family: var(--fontPrimaryStack);
    font-weight: 800;
}
.Experience-Date {
    font-family: var(--fontPrimaryStack);
    flex-shrink: 0;
}
.Experience-Details,
.Experience-Details p {
    font-family: var(--fontSecondaryStack);
}
.Experience-Details h5 {
    font-size: var(--textSize100);
    color: var(--gray500);
}
.Experience-Details dl {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-block-end: var(--textSize50);
}
.Experience-Details li {
    font-size: var(--textSize50);
    font-family: var(--fontPrimaryStack);
}