
.switch {
    background-color: var(--gray600);
    border-radius: 1rem;
    cursor: pointer;
    display: inline-block;
    height: 1rem;
    padding: 3px;
    position: relative;
    vertical-align: top;
    width: 2.25rem;

    &.checked {
        background-color: var(--oceanBlue300)
    }
}
.switch-input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}
.switch-label {
    background: var(--gray300) none repeat scroll 0 0;
    border-radius: inherit;
    display: block;
    // font-size: 10px;
    height: inherit;
    position: relative;
    text-transform: uppercase;
    transition: all 0.15s ease-out 0s;
}
.switch-label::before, .switch-label::after {
    line-height: 1;
    margin-top: -0.5em;
    position: absolute;
    top: 50%;
    transition: inherit;
}
/*
.switch-label::before {
    color: #aaa;
    content: attr(data-off);
    right: 11px;
}
.switch-label::after {
    color: white;
    content: attr(data-on);
    left: 11px;
    opacity: 0;   
}
*/

.switch-input:checked ~ .switch-label {
    background: var(--oceanBlue300) none repeat scroll 0 0;
}
.switch-input:checked ~ .switch-handle {
    border-color: var(--oceanBlue300);
}
/*
.switch-input:checked ~ .switch-label::before {
    opacity: 0;
}
.switch-input:checked ~ .switch-label::after {
    opacity: 1;
}
*/
.switch-handle {
    background-color: var(--background);
    border-radius: 1rem;
    border: 3px solid var(--gray600);
    height: 1rem;
    left: 0;
    position: absolute;
    top: 0;
    transition: left 0.15s ease-out 0s;
    width: 1rem;
}
.switch-handle::before {
    background-color: var(--background);
    border-radius: 6px;
    content: "";
    height: 12px;
    left: 50%;
    margin: -6px 0 0 -6px;
    position: absolute;
    top: 50%;
    width: 12px;
}
.switch-input:checked ~ .switch-handle {
    left: calc(2.5rem - 1.25rem);
}

