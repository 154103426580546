
/* Providing defaults... */
@media (prefers-color-scheme: dark) {
  html {
    --background: #1d1d1d;
    background-color: var(--background);
    --dropShadow: rgba(0, 0, 0, 0.25);
  }
  .App,
  .go2197912528 > div {
    --background: #1d1d1d;
    --gray50: #111111;
    --gray100: #1d1d1d;
    --gray200: #2c2c2c;
    --gray300: #474747;
    --gray400: #686868;
    --gray500: #848484;
    --gray600: #9b9b9b;
    --gray700: #c8c8c8;
    --gray800: #f2f2f2;
    --oceanBlue100: #0d2f3d;
    --oceanBlue200: #21717f;
    --oceanBlue300: #3d9093;
    --oceanBlue400: #66ada7;
    --oceanBlue500: #9dccc0;
    --oceanBlue600: #d1e7de;
    --burntOrange100: #5f0102;
    --burntOrange200: #ae4810;
    --burntOrange300: #cb6a0e;
    --burntOrange400: #e28e17;
    --burntOrange500: #f6b84a;
    --burntOrange600: #ffdea0;
    --purple100: #4b0e98;
    --purple200: #6922bd;
    --purple300: #8c39df;
    --purple400: #ae5afb;
    --purple500: #cd8bff;
    --purple600: #e5c2ff;

    --ellucianLogoText: #f0f3f3;
    --ellucianLogoMark: #9952a7;
    --viasatLogoText: #e5eff6;

    --headerColor: var(--oceanBlue400);
    --buttonColor: var(--oceanBlue400);
    --buttonColorHover: var(--oceanBlue500);
    --buttonColorPressed: var(--oceanBlue600);
  }
}
.App.dark {
  --background: #1d1d1d;
  --gray50: #111111;
  --gray100: #1d1d1d;
  --gray200: #2c2c2c;
  --gray300: #474747;
  --gray400: #686868;
  --gray500: #848484;
  --gray600: #9b9b9b;
  --gray700: #c8c8c8;
  --gray800: #f2f2f2;
  --oceanBlue100: #0d2f3d;
  --oceanBlue200: #21717f;
  --oceanBlue300: #3d9093;
  --oceanBlue400: #66ada7;
  --oceanBlue500: #9dccc0;
  --oceanBlue600: #d1e7de;
  --burntOrange100: #5f0102;
  --burntOrange200: #ae4810;
  --burntOrange300: #cb6a0e;
  --burntOrange400: #e28e17;
  --burntOrange500: #f6b84a;
  --burntOrange600: #ffdea0;
  --purple100: #4b0e98;
  --purple200: #6922bd;
  --purple300: #8c39df;
  --purple400: #ae5afb;
  --purple500: #cd8bff;
  --purple600: #e5c2ff;

  --ellucianLogoText: #f0f3f3;
  --ellucianLogoMark: #9952a7;
  --viasatLogoText: #e5eff6;

  --headerColor: var(--oceanBlue400);
  --buttonColor: var(--oceanBlue400);
  --buttonColorHover: var(--oceanBlue500);
  --buttonColorPressed: var(--oceanBlue600);
}
@media (prefers-color-scheme: light) {
  html {
    --background: #ffffff;
    background-color: var(--background);
    --dropShadow: rgba(0, 0, 0, 0.15);
  }
  .App,
  .go2197912528 > div {
    --background: #ffffff;
    --gray50: #ffffff;
    --gray100: #ffffff;
    --gray200: #eaeaea;
    --gray300: #c1c1c1;
    --gray400: #959595;
    --gray500: #767676;
    --gray600: #636363;
    --gray700: #424242;
    --gray800: #262626;
    --oceanBlue100: #deeee5;
    --oceanBlue200: #54a19e;
    --oceanBlue300: #2e8289;
    --oceanBlue400: #1a6677;
    --oceanBlue500: #104b5f;
    --oceanBlue600: #0d3545;
    --burntOrange100: #ffe8ba;
    --burntOrange200: #da7e0f;
    --burntOrange300: #be5a0f;
    --burntOrange400: #a33d0e;
    --burntOrange500: #871d04;
    --burntOrange600: #690302;
    --purple100: #e8cbff;
    --purple200: #d69fff;
    --purple300: #be70ff;
    --purple400: #9e48ee;
    --purple500: #772bcc;
    --purple600: #50129f;

    --ellucianLogoText: #010202;
    --ellucianLogoMark: #682575;
    --viasatLogoText: #202E39;

    --headerColor: var(--oceanBlue300);
    --buttonColor: var(--oceanBlue200);
    --buttonColorHover: var(--oceanBlue400);
    --buttonColorPressed: var(--oceanBlue500);
  }
}
.App.light {
  --background: #ffffff;
  --gray50: #ffffff;
  --gray100: #ffffff;
  --gray200: #eaeaea;
  --gray300: #c1c1c1;
  --gray400: #959595;
  --gray500: #767676;
  --gray600: #636363;
  --gray700: #424242;
  --gray800: #262626;
  --oceanBlue100: #deeee5;
  --oceanBlue200: #54a19e;
  --oceanBlue300: #2e8289;
  --oceanBlue400: #1a6677;
  --oceanBlue500: #104b5f;
  --oceanBlue600: #0d3545;
  --burntOrange100: #ffe8ba;
  --burntOrange200: #da7e0f;
  --burntOrange300: #be5a0f;
  --burntOrange400: #a33d0e;
  --burntOrange500: #871d04;
  --burntOrange600: #690302;
  --purple100: #e8cbff;
  --purple200: #d69fff;
  --purple300: #be70ff;
  --purple400: #9e48ee;
  --purple500: #772bcc;
  --purple600: #50129f;

  --ellucianLogoText: #010202;
  --ellucianLogoMark: #682575;
  --viasatLogoText: #202E39;

  --headerColor: var(--oceanBlue300);
  --buttonColor: var(--oceanBlue200);
  --buttonColorHover: var(--oceanBlue400);
  --buttonColorPressed: var(--oceanBlue500);
}

@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}


:root {
  --fontPrimary: 'Archivo';
  --fontPrimaryWeight: 900;
  --fontPrimaryStack: var(--fontPrimary), Helvetica, Arial, sans-serif;
  --fontSecondary: "Libre Caslon Text";
  --fontSecondaryStack: var(--fontSecondary), Georgia, serif;
  --swatchSize: 48px;
  @media screen and (min-width: 600px) {
    --swatchSize: 64px;
  }

  /* @link https://utopia.fyi/type/calculator?c=320,32,1.2,2400,124,1.333,0,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  --app-padding: clamp(2.00rem, calc(1.12rem + 4.42vw), 7.75rem);

  /* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1920,22,1.333,6,1,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  --textSize25: 0.75rem;
  --textSize50: clamp(0.83rem, calc(0.79rem + 0.20vw), 1.03rem);
  --textSize100: clamp(1.00rem, calc(0.93rem + 0.38vw), 1.38rem);
  --textSize200: clamp(1.20rem, calc(1.07rem + 0.63vw), 1.83rem);
  --textSize300: clamp(1.44rem, calc(1.24rem + 1.00vw), 2.44rem);
  --textSize400: clamp(1.73rem, calc(1.42rem + 1.53vw), 3.26rem);
  --textSize500: clamp(2.07rem, calc(1.62rem + 2.27vw), 4.34rem);
  --textSize600: clamp(2.49rem, calc(1.83rem + 3.30vw), 5.79rem);
  --textSize700: clamp(2.99rem, calc(2.04rem + 4.73vw), 7.71rem);
  --textSize800: clamp(3.58rem, calc(2.24rem + 6.70vw), 10.28rem);

  --highlight: inset 0 -.4em 0 var(--oceanBlue100);
}