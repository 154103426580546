
@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.Popover-wrapper {
  // position: relative;
  height: 48px;
  display: flex;
  align-items: center;
}

.go2197912528 {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.go2197912528 > div,
.Popover {
  top: 8px;
  position: absolute;
  z-index: 100;
  background-color: var(--gray50);
  right: -16px;
  width: auto;
  width: 240px;
  // max-width: 80vw;
  display: flex;
  border-width: 1px;
  border-style:  solid;
  border-color: var(--gray200);
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  box-shadow: 0 8px 24px 1px var(--dropShadow), 0 4px 8px 1px var(--dropShadow);
  flex-direction: column;
}

.HeaderButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  padding: 8px;
  margin: 0;
  display: flex;
  cursor: pointer;
}

.HeaderButton svg {
  height: 24px;
  width: 24px;
  opacity: 0.8;
  @include TranslationAnimation;
  
  &:hover {
    opacity: 1;
    @include TranslationAnimation;
  }
  &:active {
    opacity: 1;
    @include TranslationAnimation;
  }
}

label {
  font-family: var(--fontPrimaryStack);
  font-weight: 800;
  font-size: var(--textSize50);
  color: var(--gray800)
}
