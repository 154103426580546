
// :root {
//   --app-padding: 32px;
//   @media screen and (min-width: 600px) {
//     --app-padding: 64px;
//   }
// }

@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

body {
  font-size: 112.5%; /* 18px */
}

.App {
  text-align: start;
  margin:0;
  padding:0;
  font-family: var(--fontSecondaryStack);
  background-color: var(--background);
  color: var(--gray800);
  font-size: var(--textSize100);
  line-height: 1.5;
  overflow-x: clip;
  overflow-y: auto;
  // height: 100vh;
  // position: relative;
}


.App-content > div {
  // width: calc(100vw - 64px);
  // max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  // @media screen and (min-width: 600px) {
  //   width: calc(100vw - 84px);
  // }
}

.App-content,
.App-footer-content,
.App-header-content {
  padding: 0 var(--app-padding);
  // margin: 0 auto;
  // max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.App-content {
  // position: relative;
  align-items: center;
  padding-top: 80px;
}

.App-header-content {
  // padding-top: 1rem;
}

.App-header-content--right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  // gap: 18px;
}

.App-footer-content {
  padding: 0;
  @media screen and (min-width: 600px) {
    padding: 3em 0 0 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--fontPrimaryStack);
  font-weight: var(--fontPrimaryWeight);
  margin-bottom: 0.25em;
  margin-top: 1.25em;
  line-height: 1.2;
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  color: var(--gray800);
}

h1, h2, h3, h4 {
  -webkit-font-smoothing: subpixel-antialiased;
}

h5, h6 {
  // font-weight: 600;
  -webkit-font-smoothing: subpixel-antialiased;
}

.h1,
h1 {
  font-size: var(--textSize700);
  z-index:1;
  line-height: 1;
  max-width: max-content;
  text-align: center;
} 

.highlight {
  box-shadow: inset 0 -.4em 0 var(--oceanBlue100);
}

h2 {
  font-size: var(--textSize500);
}
h3 {
  font-size: var(--textSize400);
}
h4 {
  font-size: var(--textSize300);
  margin-bottom: 0;
}
h5 {
  font-size: var(--textSize200);
}
h6,
.h6 {
  font-size: var(--textSize100);
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.04em;
  text-align: left;
  color: var(--gray500);
}
.h7 {
  font-size: var(--textSize50);
}

p {
  font-size: var(--textSize100);
  -webkit-font-smoothing: subpixel-antialiased;
  max-width: 70ch;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

p.small {
  font-size: var(--textSize50);
}

p.large {
  font-size: var(--textSize200);
}

p + p {
  margin-block-start: 0.5em;
}

h5 + p,
h5 + ul {
  margin-top: 0;
}

code {
  font-family: 'Hack', Menlo, Monaco, monospace;
  font-size: 0.825em;
  display: inline;
  background-color: var(--gray200);
  border: 1px solid var(--gray300);
  color: var(--burntOrange400);
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 2px;
}

.body1 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize500);
}
.body2 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize400);
}
.body3 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize300);
}
.body4 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize200);
}
.body5 {
  font-family: 'Lato', sans-serif;
  font-size: var(--textSize100);
}

.projectHeader,
.HomeSectionHeader {
  font-size: var(--textSize500);
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: flex-start;
  flex-direction: row;
  gap: 0;
}
.projectHeader {
  margin-top: 1.25em;
}
.projectHeader h2,
.HomeSection h2 {
  z-index: 1;
  margin-top: 0;

  + .projectHeader_Link.button {
    margin-top: 1rem;
  }
}

.projectHeader_Link,
.HomeSectionHeader_Link {
  font-size: var(--textSize100);
}

.HomeSection--longForm {
  align-self: flex-start;

  p {
    font-size: calc(var(--textSize100) * 0.875)
  }
}

.projectCardWrapper {
  margin: 4em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--app-padding);
  // row-gap: calc(var(--app-padding) * 2);
  margin-bottom: var(--textSize800);
  justify-content: space-between;

  h2 {
    align-self: center;
  }
}


.logo {
  display: none;

  @media screen and (min-width: 600px) {
    display: inline-flex;
    align-self: flex-start;
  }
}

.logo svg {
  height: 56px;
  margin-bottom: -18px;
  fill: var(--gray600);
}

.App-header {
  background-color: var(--gray100);
  // box-shadow: 0 20px 20px 10px var(--gray100);
  height: 80px;
  font-size: 1rem;
  color: var(--gray700);
  position: fixed;
  width: 100vw;
  z-index:10;
  margin-top: -16px;
  border-bottom: 1px solid var(--gray200);
  display: flex;
  align-items: center;

  @media screen and (min-width: 600px) {
    margin-top: 0;
  }
}

.App-header > div {
  flex-grow: 1;
}

.App-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.App-link {
  color: #61dafb;
}

.intro {
  height: 80vh;
  min-height: 400px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-bottom: var(--app-padding);

  @media screen and (min-width: 800px) {
    height: 60vh;
    align-items: flex-end;
    justify-content: space-between;
    gap: var(--app-padding);
  }
}

.intro_Text {
  font-size: var(--textSize200);
  margin-bottom: 0;
}


.intro_Heading {
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: -0.035em;
  font-size: var(--textSize800);
  text-align: start;
  align-self: inherit;
}

.intro_Heading::after {
  display: none;
}

.intro_SubHeading {
  color: var(--gray700);
  max-width: 40ch;
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--fontSecondaryStack);
  font-weight: 400;
  font-size: var(--textSize300);
  line-height: 1.3;
  padding-bottom: clamp(0.50rem, calc(0.23rem + 0.54vw), 0.88rem);
  
  @media screen and (min-width: 800px) {
    max-width: 26ch;
  }
} 

.intro_Section {
  height: auto;
  margin: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--textSize100);
  height: 100%;
  max-width: none;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--app-padding);
    align-items: flex-end;
  }
}
.intro_SpecializationList-Wrapper {
  display: flex;
  flex-direction: column;
  width: max-content;
  align-self: flex-end;

  @media screen and (min-width: 800px) {
    margin-top: 2rem;
  }
}
.intro_SpecializationList {
  font-size: var(--textSize50);
  font-family: var(--fontPrimaryStack);
  margin: 0;
  list-style: none;
  // padding-inline-start: var(--textSize100);
  padding-inline-start: 0;
  color: var(--gray600);
}
.intro_SpecializationList li {
  position: relative;
}
.intro_SpecializationList li::after {
  content:"→";
  position: absolute;
  left: calc(-1 * var(--textSize100));
  color: var(--gray300);
}

.HomeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(50vh - 144px);
  border-radius: 1.5rem;
  padding: var(--textSize700) 0;
  margin-bottom: 0;
  max-width: none;

  // @media screen and (min-width: 800px) {
  //   margin-bottom: 4rem;
  // }
}
.HomeSection_Blue {
  height: fit-content;
  min-height: calc(80vh - 300px);
  background-color: var(--oceanBlue500);
  // padding: var(--textSize100);
  padding: var(--app-padding) var(--textSize100);
  color: var(--gray100);
  margin-bottom: var(--textSize800);
  // height: auto;
  .HomeSectionHeader {
    margin-bottom: 0.5em;
  }
  @media screen and (min-width: 800px) {
    padding: var(--textSize700) 0;
  }
}
.HomeSection_Blue h2 {
  color: var(--gray100);
}

.HomeSection h2 {
  margin-top: 0;
}

.HomeSection_Item {
  display: grid;
  grid-template-rows: auto auto;
  gap: var(--app-padding);

  .projectHeader_Link.button {
    align-self: center;

    @media screen and (min-width: 800px) {
      align-self: flex-start;
    }
  }

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    gap: calc(var(--app-padding) / 2);
  }
}
.HomeSection_Item--offCenter {
  gap: calc(var(--app-padding) / 3);

  @media screen and (min-width: 800px) {
    grid-template-columns: 2fr 1fr;
  }
}
.HomeSection_Item--fullWidth {
  gap: calc(var(--app-padding) / 3);
  width: 100%;

  @media screen and (min-width: 800px) {
    grid-template-columns: 100%;
  }
}

.HomeSectionList {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--textSize200);
  list-style: none;
  border-top: 1px solid var(--gray200);
  margin: 0;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--gray200);
    align-items: baseline;
    padding: 1em;
  }
}

.HomeSectionList_Details {
  font-size: var(--textSize100);
  font-family: var(--fontPrimaryStack);
  font-weight: var(--fontPrimaryWeight);
  color: var(--gray500);
}

.cta {
  background: linear-gradient(to right, var(--oceanBlue400), var(--oceanBlue100));
  padding: 56px;
  color: var(--gray50);
  font-weight: 700;
}

a {
  text-underline-offset: 2px;
  color: var(--oceanBlue300)
}
a:visited {
  color: var(--purple500);
}

.colorRamp {
  display: block;
  height: var(--swatchSize);
  width: 100%;
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  @media screen and (min-width: 600px) {
    margin-bottom: 16px;
  }
}

.colorSwatch {
  height: var(--swatchSize);
  width: calc(var(--swatchSize) * 1.5);
  border-radius: 8px;
  box-shadow: inset 1px 1px 0 0 rgba(0, 0, 0, 0.1), inset -1px -1px 0 0 rgba(0, 0, 0, 0.1);
  display: inline-flex;
  // flex-direction: column;
  // justify-content:space-between;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 8px;
  @media screen and (min-width: 600px) {
    margin-right: 16px;
  }  
  margin-bottom: 8px;
  @media screen and (min-width: 600px) {
    margin-bottom: 16px;
  }

  .swatchContrastValue {
    display: flex;
    flex-direction: row;
    -webkit-font-smoothing: subpixel-antialiased;

    span {
      display: inline-flex;
      padding: 2px 0 0 6px;
      font-size: 0.75rem;
      opacity: 0.65;
    }
    span + span {
      padding: 2px 0 0 0;
    }
  }

  .swatchColorValue {
    padding: 2px 0 0 6px;
    display: block;
    font-size: 0.75rem;
    -webkit-font-smoothing: subpixel-antialiased;

  }
}

// .colorName {
//   font-size: 0.75rem;
// }

span.colorContrast {
  opacity: 1;
  font-size: 0.75rem;
}

.colorGroup {
  margin-bottom: 32px;
}

fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }

  div + div {
    margin-top: 1.25rem;
  }
}

.ArtDetails {
  font-size: var(--textSize50);
  color: var(--gray500);
  font-style: italic;
  margin-top: 0;
  margin-bottom: 0;
}

iframe {
  align-self: center;
}

.LogoWrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  @media screen and (min-width: 800px) {
    gap: 3rem;
  }
}
.LogoList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 2rem;

  @media screen and (min-width: 800px) {
    column-gap: 6rem;
  }
}
.LogoList li {
  display: flex;
  align-items: center;
  min-width:fit-content;
  height: var(--textSize700);
  transform: scale(1);
  @include TranslationAnimation;

  svg {
    width: 100px;

    @media screen and (min-width: 800px) {
      width: 196px
    }
  }
}
.LogoList li:hover,
.LogoList li:focus {
  @include TranslationAnimation;

  transform: scale(1.1);
}

.button {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  width: fit-content;
  appearance: none;
  text-decoration: none;
  font-family: 'Archivo';
  background-color: var(--buttonColor);
  color: var(--gray100);
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  border: 2px solid transparent;
  @include TranslationAnimation;

  &:hover,
  &:focus {
    background-color: var(--buttonColorHover);
  }
  &:focus {
    border-color: var(--gray100);
  }
  &:visited {
    color: var(--gray100);
  }
  &:active {
    background-color: var(--buttonColorPressed);
  }
}

.button.button-small {
  font-size: var(--textSize50);
  padding: 0.25rem 1.25rem;
}

.YouTube_Embed {
  display: flex;
  width: 100%;
  justify-content: center;
}
.YouTubeVideo {
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 1200px;
}



.resume {
  min-height: var(--textSize800);
  margin-top: var(--app-padding);
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  margin-bottom: var(--app-padding);

  @media screen and (min-width: 800px) {
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--app-padding);
  }
}

.resume_Text {
  font-size: var(--textSize200);
  margin-bottom: 0;
}


.resume_Heading {
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: -0.035em;
  font-size: var(--textSize800);
  text-align: start;
  align-self: inherit;
}

.resume_Heading::after {
  display: none;
}

.resume_SubHeading {
  color: var(--gray700);
  flex-shrink: 0;
  max-width: 40ch;
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--fontSecondaryStack);
  font-weight: 400;
  font-size: var(--textSize200);
  line-height: 1.3;
  padding-bottom: clamp(0.50rem, calc(0.05rem + 0.89vw), 1.13rem);
  
  @media screen and (min-width: 800px) {
    max-width: 26ch;
  }
} 

.resume_Section {
  height: auto;
  margin: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--textSize100);
  height: 100%;
  width: 100%;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--app-padding);
    align-items: flex-end;
  }
}

.resume_details {
  display: grid;
  grid-template-rows: auto;
  gap: var(--app-padding);
  @media screen and (min-width: 800px) {
    grid-template-columns: 3fr 2fr;
  }
  @media screen and (min-width: 1200px) {
    gap: calc(var(--app-padding) * 2);
  }
}

.resume_details-section {
  display: flex;
  flex-direction: column;
  gap: calc(var(--app-padding) / 2);
}