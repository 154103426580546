@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.App-footer-content {
  min-height: 0.25vh;
}

.socialLinks {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 6rem;
  margin-top: 1rem;
  @media screen and (min-width: 600px){
    margin-bottom: 12rem;
  }
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: transparent;
  @include TranslationAnimation;
}

.socialLink + .socialLink {
  margin-left: 2rem;
}

.socialIcon {
  height: 2rem;
  width: 2rem;
  display: block;
  fill: var(--oceanBlue200);
  @include TranslationAnimation;
}

.socialLink:hover,
.socialLink:active,
.socialLink:focus {
  background-color: var(--oceanBlue100);
  transform: scale(1.2);
  @include TranslationAnimation;
}

.socialLink:hover .socialIcon,
.socialLink:active .socialIcon,
.socialLink:focus .socialIcon {
  transform: scale(0.9);
  fill: var(--oceanBlue400);
}