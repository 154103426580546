@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.ArticleCardWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--app-padding);
  margin-top: var(--app-padding)
}

.ArticleCardWrapper--column {
  margin-top: 0;
  flex-direction: column;
  width: 100%;
}

.ArticleCard {
  display: grid;
  grid-template-areas: 
    "image"
    "details";
  column-gap: 32px;
  width: 100%;

  @include TranslationAnimation;

  @media screen and (min-width: 800px) {
    width: calc(50% - var(--app-padding)/2)
  }
}

.ArticleCard {
  border-bottom: 1px solid var(--gray200);
  padding-bottom: 3rem;
}

.ArticleCard h3 {
  color: var(--oceanBlue400);
  display: block;
  max-width: 30ch;
  position: relative;
  margin-bottom: 0;
  margin-top: 0.5em;
  z-index: 1;
  @include TranslationAnimation;
}
.ArticleCard:hover h3,
.ArticleCard:focus h3 {
  color: var(--oceanBlue500);
  @include TranslationAnimation;
}

.ArticleCard .highlight {
  box-shadow: none;
  @include TranslationAnimation;
}
.ArticleCard a:hover .highlight,
.ArticleCard a:focus .highlight {
  box-shadow: inset 0 -0.4em 0 var(--oceanBlue100);
  @include TranslationAnimation;
}

.ArticleCard p {
  color: var(--gray700);
  margin-top: 0.25em;
  max-width: 48ch;
}

.ArticleCard_Image {
  @include TranslationAnimation;
  grid-area: image;
  transform: scale(1);
  height: clamp(12.50rem, calc(9.75rem + 13.75vw), 26.25rem);
  overflow: clip;
  border-radius: 1rem;
  background-color: var(--gray200);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.ArticleCard_Image img {
  max-width: 100%;
  max-height: 100%;
  margin: 0;
}

.ArticleCard:hover .ArticleCard_Image,
.ArticleCard:focus .ArticleCard_Image,
.ArticleCard:active .ArticleCard_Image {
  @include TranslationAnimation;
  transform: scale(1.025);
}

.ArticleCard_details {
  grid-area: details;
  margin-top: 0;
}
.ArticleCard_details a {
  display: block;
  text-decoration: none;
  width: fit-content;
}

.ArticleCard .articleDetail {
  font-size: var(--textSize50);
  color: var(--gray400);
}

.ArticleCardWrapper--column .ArticleCard {
  width: 100%;
  grid-template-areas: "image details";
  grid-template-columns: 1fr 1fr;
  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 2fr;
  }
}

.ArticleCardWrapper--column .ArticleCard h3 {
  font-size: var(--textSize200);
}
.ArticleCardWrapper--column .ArticleCard p {
  font-size: var(--textSize50);
}
.ArticleCardWrapper--column .ArticleCard .ArticleCard_Image {
  height: auto;
}
