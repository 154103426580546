@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.projectCard {
  display: grid;
  grid-template-areas: 
    "image"
    "details";
  grid-template-columns: 100%;
  grid-template-rows: clamp(12.50rem, calc(9.75rem + 13.75vw), 26.25rem) auto;
  column-gap: 32px;
  width: 100%;
  text-decoration: none;
  @include TranslationAnimation;

  @media screen and (min-width: 800px) {
    width: calc(50% - var(--app-padding)/2);
  }
}

// .projectCard + .projectCard{
//   margin-top: 3em;

//   @media screen and (min-width: 600px) {
//     margin-top: 8em;
//   }
// }

.projectCard h4 {
  color: var(--oceanBlue400);
  display: inline;
  width: auto;
  position: relative;
  margin-bottom: 0;
  margin-top: 0.5em;
  z-index: 1;
  @include TranslationAnimation;
}
.projectCard:hover h4,
.projectCard:focus h4 {
  color: var(--oceanBlue500);
  @include TranslationAnimation;
}

.projectCard .highlight {
  box-shadow: none;
  @include TranslationAnimation;
}
.projectCard:hover .highlight,
.projectCard:focus .highlight {
  box-shadow: inset 0 -0.4em 0 var(--oceanBlue100);
  @include TranslationAnimation;
}

.projectCard p {
  color: var(--gray700);
  margin-top: 0.25em;
  max-width: 48ch;
}

.projectCard .projectImage {
  @include TranslationAnimation;
  grid-area: image;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 50vh;
  transform: scale(1);
  margin: 0;
  max-width: 100%;
  background-color: var(--gray200);
  border-radius: 0.5rem;
  overflow:clip;
  padding: calc(var(--app-padding) / 2);

  // @media screen and (min-width: 800px) {
  //   max-height: 50vh;
  // }
}

.projectCard .projectImage img {
  max-width: 100%;
  max-height: 100%;
}

.projectCard:hover .projectImage,
.projectCard:focus .projectImage,
.projectCard:active .projectImage {
  @include TranslationAnimation;
  transform: scale(1.025);
}

.projectCard_details {
  grid-area: details;
  margin-top: 1rem;
}
.projectCard_details a {
  display: block;
  text-decoration: none;
}