.badge {
    display: inline-flex;
    font-size: var(--textSize25);
    padding: 0.25em 0.75em;
    border-radius: 6px;
    font-weight: 600;
    color: var(--background);
    font-family: var(--fontPrimaryStack);
    width: auto;
    border: 1px solid gray;
}

.badge--designTool {
    background-color: var(--burntOrange100);
    border-color: var(--burntOrange300);
    color: var(--burntOrange600);
}
.badge--website {
    background-color: var(--gray200);
    border-color: var(--gray400);
    color: var(--gray800);
}
.badge--designSystem {
    background-color: var(--purple100);
    border-color: var(--purple300);
    color: var(--purple600);
}