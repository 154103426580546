
@mixin TranslationAnimation {
  transition: all 0.2s ease-in-out;
}

.navigation-sub {
  height: 48px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (min-width: 600px) {
    padding-top: 0;
  }
}

.navigation-sub .item {
  position: relative;
  font-family: var(--fontPrimaryStack);
  text-decoration: none;
  color: var(--oceanBlue300);
  height: 100%;
  align-items: center;
  display: flex;
  font-weight: 800;
  font-size: 0.75rem;
  @include TranslationAnimation;

  @media screen and (min-width: 600px) {
    font-size: var(--textSize50);
  }
}


.navigation-sub .item:hover,
.navigation-sub .item:focus,
.navigation-sub .item:active,
.navigation-sub .item.selected {
  color: var(--oceanBlue600);
  @include TranslationAnimation;
}


.navigation-sub .item::after {
  display: block;
  position: absolute;
  width: 0;
  height: 4px;
  bottom: 0;
  background-color: var(--oceanBlue100);
  left: 50%;
  content: ' ';
  @include TranslationAnimation;
}

.navigation-sub .item + .item {
  margin-left: 16px;

  @media screen and (min-width: 600px) {
    margin-left: 24px;
  }
}

.navigation-sub .item.selected::after,
.navigation-sub .item:hover::after  {
  width: 100%;
  left: 0;
  @include TranslationAnimation;
}
.navigation-sub .item.selected::after {
  background-color: var(--oceanBlue200);
}

.navigation-sub + .Popover-wrapper {
  margin-left: 18px;
}