/* Lato sans google fonts link */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'); */
@import url("https://use.typekit.net/tvu4caq.css");
/* @import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;700;900&family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap');


/* @font-face {
  font-family: 'YoungSerif';
  src: local('YoungSerif'),
        url('./fonts/YoungSerif-Regular.woff2') format("woff2"),
        url('./fonts/YoungSerif-Regular.woff') format("woff"),
        url('./fonts/YoungSerif-Regular.ttf') format("ttf");;  
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Hack';
  src: local('Hack'),
        url('./fonts/Hack-Regular.ttf') format("ttf"),
        url('./fonts/Hack-Italic.ttf') format("ttf"),
        url('./fonts/Hack-Bold.ttf') format("ttf"),
        url('./fonts/Hack-BoldItalic.ttf') format("ttf");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  padding: 0;
  font-family: "Libre Caslon Text", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  -webkit-font-smoothing: subpixel-antialiased;
}